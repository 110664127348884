import { NgModule, APP_INITIALIZER, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';

import { GAPIService } from '@dis/gapi';
import {
  AuthModule,
  appInitGapi,
  RefreshGapiTokenService
} from '@dis/auth';

import { cdnInterceptor, CdnInterceptor } from '@dis/shared';

import { SentryModule } from '@dis/modules';

import { FirebaseModule } from './core/modules/firebase-v9.module';
import { SharedModule } from './shared/shared.module';
import { UiModule } from './ui/ui.module';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { AppNgxsModule } from './core/modules/ngxs.module';

import { TestingModule } from './testing/testing.module';
import { SettingsComponent } from './components/settings/settings.component';
import { FcmComponent } from './standalone/firebase-cloud-messaging/fcm';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
  ],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    FirebaseModule,
    FcmComponent,
    SharedModule,
    UiModule,
    /** States Mangement */
    AppNgxsModule,
    /** Domain Modules */
    AuthModule,
    TestingModule,
    /** Service Modules */
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode() && environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SentryModule,
    /** Routing */
    AppRoutingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitGapi,
      deps: [GAPIService, RefreshGapiTokenService],
      multi: true
    },
    provideHttpClient(
      withFetch(),
      withInterceptors([
        cdnInterceptor
      ])
    )
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
