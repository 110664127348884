import { NgModule } from '@angular/core';

import { environment } from 'projects/jtms-front-end/src/environments/environment';

import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { ngxsConfig } from '../config/ngxs.config';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

import { NavTreeState } from 'projects/jtms-front-end/src/app/ui/nav-tree/state/nav-tree.state';

import { AuthState } from '@dis/auth';
import {
  AssembliesState,
  BuildsState,
  ComponentsState,
  ContainersState,
  EquipmentState,
  JobsState,
  LayoutState,
  PartsState,
  ProjectsState,
  QuotesState,
  TableState,
  InvoicesState,
} from '@dis/shared';
import { NGXS_APP_STORAGE_CONFIG } from './ngxs-storage-config';

const MODULES = [
  NgxsModule.forRoot([
    AuthState,
    /**
     * App Context related states
     */
    LayoutState,
    NavTreeState,
    TableState,
    /**
     * JTMS Collection States
     */
    QuotesState,
    ProjectsState,
    JobsState,
    BuildsState,
    AssembliesState,
    ComponentsState,
    PartsState,
    ContainersState,
    InvoicesState,
    EquipmentState,
  ], ngxsConfig),
  /**
   * Leave before other plugins so that local data is available first.
   */
  NgxsStoragePluginModule.forRoot({
    ...NGXS_APP_STORAGE_CONFIG
  }),
  NgxsFormPluginModule.forRoot(),
  NgxsRouterPluginModule.forRoot(),
  // NgxsFirestoreModule.forRoot(),
  // These need to always be included last.
  environment.ngxs.plugins,

];

@NgModule({
  declarations: [],
  imports: [
    MODULES
  ],
  exports: [
    MODULES
  ],
})
export class AppNgxsModule { }
